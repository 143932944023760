import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { PostGrid, BlogHeader, Layout, FeaturedPosts } from 'components';
import './blog.scss';

const Blog = () => {
  const blogData = useStaticQuery(graphql`query BlogPost {
  allPosts: allPrismicBlogPost(sort: {order: ASC, fields: data___timestamp}) {
    nodes {
      data {
        excerpt {
          html
          text
        }
        featured_image {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: FULL_WIDTH)
            }
          }
        }
        is_featured
        main_featured
        title {
          html
          text
        }
      }
      uid
    }
  }
  featuredPosts: allPrismicBlogPost(
    filter: {data: {is_featured: {eq: true}}}
    limit: 3
    sort: {fields: data___timestamp, order: DESC}
  ) {
    nodes {
      data {
        excerpt {
          html
          text
        }
        featured_image {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: FULL_WIDTH)
            }
          }
        }
        is_featured
        main_featured
        title {
          html
          text
        }
      }
      uid
    }
  }
}
`);

  const { allPosts, featuredPosts } = blogData;
  const { nodes: posts } = allPosts;
  const { nodes: featured } = featuredPosts;

  return (
    <Layout className="blog">
      <section className="blog-container">
        <BlogHeader />
        {featured.length > 0 && <FeaturedPosts posts={featured} title="Featured Posts" />}
        <PostGrid posts={posts} title="All Posts" isPaginated />
      </section>
    </Layout>
  );
};

export default Blog;
